<template>
  <v-tabs
    v-if="selected != null && planElem.type.includes('matrix')"
    show-arrows
    v-model="activeTab"
    :slider-color="planElem.color"
  >
    <v-tab
      v-for="(item, i) in planElem.bloques[0].days.length"
      :key="'Dia' + i"
      :disabled="i >= userPlan"
    >
      Día #{{ i + 1 }}

      <span class="caption ml-2" v-if="i == workoutsCompleted - 1 && iterIndex == 0">(HOY)</span>

      <v-chip v-if="i >= userPlan" x-small class="ml-1">
        Aumentar plan
      </v-chip>
    </v-tab>

    <v-tab-item v-for="(dia, index) in userPlan" :key="'Diatab' + index">
      <v-card
        v-for="(bloque) in planElem.bloques"
        :key="bloque.title"
        class="mb-0 mx-1"
        :id="activeTab == index ? 'v-step-bloque-1' : ''"
      >
        <v-divider></v-divider>
        <v-card-title>
          {{ bloque.title }}

          <v-spacer></v-spacer>
          <span class="caption" :id="activeTab == index ? 'v-step-metodo-1' : ''">
            {{ getMetodo(bloque.days) }}
          </span>

          <span :id="activeTab == index ? 'v-step-rpe-1' : ''" class="caption" v-if="getRPE(bloque.days)">
            <v-avatar
              size="30"
              class="ml-1"
              
              :color="rpeColor(getRPE(bloque.days))"
            >
              {{ getRPE(bloque.days) || '' }}
            </v-avatar>
          </span>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text
          v-if="planElem.type == 'matrixtext'"
          
          v-html="getEjercicioDiario(bloque, index)"
          :id="activeTab == index ? 'v-step-ejercicios-1' : ''"
        ></v-card-text>

        <v-card-text v-if="planElem.type == 'matrix'" class="pa-0">
          <v-list v-if="bloque.days[index] && bloque.days[index].ejercicioDiario">
            <v-list-item-group color="primary">
              <ExerciseListItem
                :id="id"
                :index="index"
                :cuant="getCuantificacion(bloque.days)"
                v-for="(id, index) in bloque.days[index].ejercicioDiario"
                :key="id"
              ></ExerciseListItem>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import ExerciseListItem from './ExerciseListItem.vue'; // Adjust the path accordingly

export default {
  name: 'PlanComponent',
  components: {
    ExerciseListItem,
  },
  props: {
    planElem: {
      type: Object,
      required: true,
    },
    planificationweek: {
      type: Number,
      default: 0,
    },
    workoutsCompleted: {
      type: Number,
      default: 0,
    },
    userPlan: {
      type: Number,
      default: 0,
    },
    selected: {
      type: Object,
      default: () => ({ type: 'matrix' }),
    },
  },
  data() {
    return {
      activeTab: 0,
      iterIndex: 0,
    };
  },
  methods: {
    getMetodo(weeks) {
      const index = this.$props.planificationweek % weeks.length;
      if(index < 0) return '';

      return weeks[index].metodo || '';
    },
    getRPE(weeks) {
      const index = this.$props.planificationweek % weeks.length;
      if(index < 0) return '';
      
      return weeks[index].rpe || '';
    },
    rpeColor(value) {
      value = parseInt(value)
      if (value === 1) return 'green lighten-2';
      else if (value === 2) return 'green lighten-1';
      else if (value === 3) return 'green ';
      else if (value === 4) return 'green darken-1';
      else if (value == 5) return 'green darken-2';
      else if (value === 6) return 'yellow darken-2';
      else if (value === 7) return 'orange';
      else if (value === 8) return 'orange darken-3';
      else if (value === 9) return 'red';
      else if (value === 10) return 'red darken-3';
    },
    getCuantificacion(weeks) {
        const index = this.$props.planificationweek % weeks.length;
        if(index < 0) return '';
        return weeks[index].cuantificacion || '';
    },

    getEjercicioDiario(bloque, index) {
        try{
          
          if(bloque.days[index] && !bloque.days[index].ejercicioDiario){
            return 'Sin ejercicios';
          }

          if(bloque.days[index] && bloque.days[index].ejercicioDiario && bloque.days[index].ejercicioDiario.trim() == ''){
            return 'Sin ejercicios';
          }


          let html_content=  bloque.days[index] ? bloque.days[index].ejercicioDiario.replace(/\n/g, '<br>') : ''
          return html_content;
        }catch(e){
          console.log('index',index)
          console.log(e)
          return e;
        }
    }
  },
  mounted(){
    if(this.$props.workoutsCompleted){
        this.activeTab = this.$props.workoutsCompleted -1 || 0;
    }
  }
};
</script>

<style scoped>
/* Add your styles here if needed */
</style>
