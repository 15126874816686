<template>
    <v-row no-gutters>
        <v-col cols="12">
        <v-divider></v-divider>
            <v-card flat>
                <v-card-text class=" pa-0">
                    <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>

                    <v-list dense class="py-0 " v-else>
                        <v-alert text type="info" v-if="dayExercises.length == 0">
                            No hay ejercicios en esta rutina
                        </v-alert>
                            <v-list-item dense v-for="(exercise, index) in dayExercises" :key="index"
                                @click="exercise.isCluster ? null : selectExercise(exercise)"
                                class="drag-handle px-0">
                                <!-- Render clusters differently -->
                                <div v-if="exercise.isCluster && exercise.isCluster == true" class="cluster-container">
                                    <v-list-item class="drag-handle">
                                        <v-list-item-avatar>
                                            {{ index + 1 }} )
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title style="font-weight: bold;text-transform:uppercase">
                                                <template v-if="exercise.title">
                                                    {{ exercise.title }}
                                                </template>
                                                <template v-else>
                                                    {{ exercise.exercises.length }} Ejercicios en este grupo
                                                </template>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{ exercise.sets }} sets
                                            </v-list-item-subtitle>

                                            
                                        </v-list-item-content>
                                          
                                    </v-list-item>
                                    <!-- Display exercises inside the cluster -->
                                    <v-list dense class="cluster-exercise-list">
                                            <v-list-item v-for="(subExercise, exIndex) in exercise.exercises"
                                                @click.prevent="selectExercise(subExercise)"
                                                :key="exIndex" class="clustered-exercise">
                                                <v-list-item-avatar>
                                                    {{ index + 1 }}.{{ exIndex + 1 }}
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ subExercise.data.name
                                                        }}</v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        {{ subExercise.type === 'reps' ?
                                                            subExercise.value + ' reps' : subExercise.value + `''` }}
                                                    </v-list-item-subtitle>
                                                  
                                                </v-list-item-content>
                                                    <v-list-item-action>
                                                    <v-icon>mdi-chevron-right</v-icon>
                                                </v-list-item-action>
                                            </v-list-item>
                                    </v-list>
                                </div>
                                <!-- Render regular exercises -->
                                <template v-else>
                                    <v-list-item-avatar>
                                        {{ index + 1 }} )
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title style="font-weight: bold;text-transform:uppercase">
                                            {{ exercise.data.name }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ exercise.sets }} x {{ exercise.type === 'reps' ? exercise.value +
                                                ' Repeticiones' :
                                                exercise.value + `''` }}

                                                {{exercise.restBetweenSets? 'x ' + exercise.restBetweenSets + `''` : ''}}

                                            <v-btn color="primary" v-if="exercise.type === 'time'" x-small text @click.stop="setChronoSetup(exercise)">
                                                abrir cronometro
                                            </v-btn>


                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action class="pr-4">



                                    <v-icon>mdi-chevron-right</v-icon>
                                </v-list-item-action>
                                </template>
                            </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-col>

         <v-dialog v-model="dialog" max-width="500">
            <v-card v-if="selectedExercise">
              
                <v-card-text class="pa-0"  v-if="selectedExercise.thumbnail || selectedExercise.videoURL || selectedExercise.url">
                    <v-carousel v-model="carousel" height="unset"  :show-arrows="getLengthOfMedia> 1" hide-delimiters >
                                    <v-carousel-item v-if="selectedExercise.thumbnail">
                                        <v-img class="mx-auto" :src="selectedExercise.thumbnail" aspect-ratio="1"></v-img>
                                    </v-carousel-item>
                                    <v-carousel-item v-if="selectedExercise.videoURL">
                                        <v-responsive>
                                            <video width="100%" height="250px" :src="selectedExercise.videoURL" controls></video>
                                        </v-responsive>
                                    </v-carousel-item>
                                    <v-carousel-item v-if="selectedExercise.url && dialog">
                                        <v-responsive aspect-ratio="16/9">
                                            <iframe width="100%" height="250px" :src="getURL(selectedExercise.url)" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </v-responsive>
                                    </v-carousel-item>
                                </v-carousel>
                </v-card-text>
                <v-card-title class="py-2">
                    {{ selectedExercise.name }}
                </v-card-title>
                 <v-divider></v-divider>
                <v-card-text class="pt-2">
                           <strong>Descripción:</strong><br/>   
                             {{ selectedExercise.description }}         
                </v-card-text>
                 <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary darken-1" @click="dialog = false">
                                    Volver
                                </v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog v-model="chrono" persistent fullscreen>
            <v-card class="rounded-0">
                <v-card-title>
                    <v-btn icon @click="chrono=false">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="chrono=false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                   
                </v-card-title>
                <ChronoAppVue v-if="chrono"  :sets="chronoSetup.sets"  :type="chronoSetup.type" :setSecondsDuration="chronoSetup.seconds" :restSecondsDuration="chronoSetup.restSeconds"
                
                         ref='chrono'></ChronoAppVue>
                
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import draggable from 'vuedraggable';
import { getFirestore, collection, getDocs, doc, getDoc, setDoc, addDoc, deleteDoc, updateDoc, runTransaction, Timestamp } from 'firebase/firestore';

import ChronoAppVue from '@/views/ChronoApp.vue';


export default {
    props: {
        exercises: {
            type: Array,
            required: true
        },
    },
    components: {
        draggable, ChronoAppVue
    },
    data() {
        return {
            loading : true,
            dialog: false,
            dayExercises: [], 
            selectedExercise: null,
            carousel: 0,
            chrono: false,
            chronoSetup:{
                type: 'workrest',
                seconds: 30,
                restSeconds: 10,
                sets: 1
            }
        };
    },
    mounted() {
        let promises = this.$props.exercises.map(async (exercise) => {
            if (exercise.isCluster) {
                // Create an array of promises for the cluster's exercises
                let exercisesPromises = exercise.exercises.map(async (ex) => {
                    // Retrieve each exercise data from the database
                    let exerciseData = await this.getExercise(ex.id);
                    return {
                        ...ex, // Keep original exercise attributes
                        data: exerciseData // Add the retrieved data to the exercise
                    };
                });

                // Wait for all exercises within the cluster to resolve
                exercise.exercises = await Promise.all(exercisesPromises);
            } else {
                // Retrieve the data for a non-cluster exercise
                let exerciseData = await this.getExercise(exercise.id);
                exercise = {
                    ...exercise, // Keep original exercise attributes
                    data: exerciseData // Add the retrieved data to the exercise
                };
            }
            return exercise;
        });

        Promise.all(promises).then((exercises) => {
            this.dayExercises = exercises;

            this.loading = false;
        });   
    },
    methods: {
        setChronoSetup(exercise){

            if(exercise.type == 'time'){
                this.chronoSetup = {
                    type: exercise.restBetweenSets? 'workrest':'interval',
                    sets: exercise.sets,
                    seconds: parseInt(exercise.value),
                    restSeconds: exercise.restBetweenSets? parseInt(exercise.restBetweenSets): null
                }

                this.chrono = true;
            }
            
        },
         getURL(url) {
            return url.replace("watch?v=", "embed/");
        },
         async getExercise(id) {
            const db = getFirestore();
            const docRef = doc(db, "exercises", id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
               
                return docSnap.data();
            }
        },
        get(){
            return this.dayExercises;
        },
        set(data){
            this.dayExercises = data;
        },
        selectExercise(exercise) {
            this.selectedExercise = exercise.data;
            this.dialog = true;
        },
    },
    computed: {
        getLengthOfMedia() {
            let exercise = this.selectedExercise;
            let length = 0;
            if (exercise.videoURL) {
                length++;
            }
            if (exercise.thumbnail) {
                length++;
            }
            if (exercise.url) {
                length++;
            }
            return length;
        }
    },
};
</script>
<style scoped>

.v-window__next{
    z-index: 9999;
}

.v-window__prev{
    z-index: 9999;
}
.selected-item {
    background-color: #e0f7fa;
    /* Highlight selected items */
}

.cluster-container {
    width: 100%;
    background-color: #f0f4f7;
    border-left: 2px solid #42a5f5;

}
.clustered-exercise {
    margin-bottom: 5px;
}

.theme--dark .selected-item {
    background-color: rgba(255, 255, 255, 0.2);
}

.theme--light .selected-item {
    background-color: rgba(0, 0, 0, 0.2);
}


.theme--dark .cluster-container {
    background-color: #1f2d3d;
    border-left-color: #f0f4f7;
}

.theme--light .cluster-container {
    background-color: #f0f4f7;
    border-left-color: #1f2d3d;
}

.cluster-container {
    width: 100%;
    border-left: 2px solid;
}



</style>
