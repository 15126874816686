<template>
    <v-list-item @click="dialog = true" class="rounded-lg">
        <v-list-item-content>

            <v-list-item-title>{{ name }}</v-list-item-title>

             <v-list-item-subtitle>
                {{$props.cuant}}
             </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
            <v-icon>mdi-chevron-right</v-icon>
        </v-list-item-action>

        <v-dialog v-model="dialog" max-width="500">
            <v-card>
               
                <v-card-text class="pa-0"  v-if="urlImg || exercise.videoURL || exercise.url">
                    <v-carousel v-model="carousel" height="unset"  :show-arrows="getLengthOfMedia> 1" hide-delimiters >
                                    <v-carousel-item v-if="urlImg">
                                        <v-img class="mx-auto" :src="urlImg" aspect-ratio="1"></v-img>
                                    </v-carousel-item>

                                    <v-carousel-item v-if="exercise.videoURL">
                                        <v-responsive>
                                            <video width="100%" height="250px" :src="urlVideo" controls></video>
                                        </v-responsive>
                                    </v-carousel-item>

                                    <v-carousel-item v-if="exercise.url && dialog">
                                        <v-responsive aspect-ratio="16/9">
                                            <iframe width="100%" height="250px" :src="getURL(exercise.url)" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </v-responsive>
                                    </v-carousel-item>


                                </v-carousel>
                </v-card-text>

                 <v-card-title class="py-2">
                    {{ name }}
                  
                </v-card-title>
                                            <v-divider></v-divider>

                <v-card-text class=" pt-2">
                          
                                <strong>Descripción:</strong><br/>   

                                {{ description }}
                              
                        </v-card-text>
                 <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary darken-1" @click="dialog = false">
                                    Volver
                                </v-btn>
                                <v-spacer></v-spacer>

                            </v-card-actions>
            </v-card>

        </v-dialog>
    </v-list-item>
</template>

<script>
import { getFirestore, collection, query, where, setDoc, doc, getDoc, addDoc, deleteDoc, updateDoc, runTransaction, Timestamp } from "firebase/firestore";

export default {
    data() {
        return {
            dialog: false,
            name: null,
            description: null,
            urlImg: null,
            urlVideo: null,
            loading: true,
            exercise:{},
            carousel: 0
        }
    },
    props: {
        id: {
            type: String,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        cuant: {
            required: true
        }
    },
    methods: {
        async getExercise() {
            const db = getFirestore();
            const docRef = doc(db, "exercises", this.id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                this.name = docSnap.data().name;
                this.description = docSnap.data().description;
                this.urlImg = docSnap.data().thumbnail;
                this.urlVideo = docSnap.data().videoURL;
                this.exercise = docSnap.data();
                this.loading = false;
            }
        },

        getURL(url) {
            return url.replace("watch?v=", "embed/");
        }
    },
    async mounted() {
        await this.getExercise();
    },
    computed:{
        getLengthOfMedia(){
            let exercise = this.exercise;
            let length = 0;
            if(exercise.videoURL){
                length++;
            }
            if(exercise.url){
                length++;
            }
            if(exercise.thumbnail){
                length++;
            }
            return length;
        }
    }

}
</script>

<style scoped>
.v-avatar {
    overflow: visible !important;
}
</style>